
body {
    margin: 0;
    /*font-family: 'Open Sans', sans-serif;*/
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	font-size: 14px
}

@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}

.spin {
	animation-name: spin;
	animation-duration: 5000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.mono {
	font-family: 'Fira Code', 'Roboto Mono',  monospace;
}

#root {
	height: 100vh;
}

.noWrap {
	white-space: nowrap;
}

.container.loginPage {
	height: 100vh;
	background-color: #0680a4;
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
	max-width: none;
}

.container.loginPage .row {
	height: 100vh;

}

.logInBox {
	background-color:rgba(0, 0, 0, 0.3);
	padding: 2rem;
	color: #FFF;
	widows: 330px;
	margin-bottom: 4rem;
}

.logInBox label {
	width: 100%;
}

.logInLogo {
	margin-bottom: 1.5rem;
	position: relative;
	max-width: 266px;
}

.logoSubtitle {
	position: absolute;
	top: 30px;
	left: 60px;
	text-transform: uppercase;
	font-size: 1.5rem;
}

.clearfix:after {
	content: " "; /* Older browser do not support empty content */
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
 } 

*:focus {outline:none !important}
*:active {outline:none !important}

.btn {
	text-transform: uppercase;
	font-weight: bolder !important;
	border: none !important;
}

.btn-primary {
	background-color: #067ea2 !important;
}

.btn-danger {
	background-color: #e44d25 !important;
}

.btn-warning {
	background-color: #e4b525;
}

.btn-success {
	background-color: #36935e !important;
}

.btn-info {
	background-color: #24aed5 !important;
}

.btn-light {
	color:#067ea2 !important;
	background-color: #FFF !important;
	border: 1px solid #ced4da;
}

.btn-transparent {
	color:#067ea2;
	background-color: transparent;
}

.btn.disabled {
	opacity: 0.3 !important;
	-webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
  	filter: grayscale(50%);
}
.frontBox {
	background-color: #b4d9e4;
	border: 4px solid #FFFFFF;
	height: 100%;
	position: relative;
	padding-bottom: 61px;
}

.frontBoxHead {
	background-color: #9bccdb;
	padding: 0.5rem;
}

.frontBoxHead h5 {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}

.frontBoxContent {
	padding: 0.5rem;
}

.frontBoxContent ul {
	margin-bottom: 0;
	padding-left: 0;
	margin-right: 0;
	margin-left: 0;
	padding-right: 0;
}

.frontBoxContent ul li {
	list-style-type: none;
	width: 100%;	
	margin-bottom: 0.4rem;
}

.frontBoxContent ul li .listBox {
	padding: 0.3rem;
	background-color: rgba(255,255,255,0.3);
	color: #222;
	font-size: 0.85rem;
	min-height: 50px;
}
.frontBoxContent ul li .listBox .logoBox {
	float: left;
	background-color: white;
	width: 35px;
	height: 35px;
	margin-right: 2%;
	border-radius: 100px;
	position: relative;
	overflow: hidden;
	border: 2px solid white;	
}
.frontBoxContent ul li .listBox .dateBox, .contactBox {
	background-color: white;
	text-align: center;
	line-height: 1rem;
	padding: 0.2rem 0 0.2rem 0;
}
		
.frontBoxContent ul li .listBox .dateBox .day, .contactBox {
	font-size: 1rem;
	display: block;
	font-weight: bold;
}

.contactBox {
	margin-right: 1rem;
}

.frontBoxContent ul li .listBox .dateBox .month {
	display: block;
}
.frontBoxContent ul li .listBox .itemName, .frontBoxContent ul li .listBox .itemInfo {
	float: left;
	line-height: 1.2rem;
	vertical-align: middle;
	padding: 0 0.5rem 0 0.5rem;
}

.frontBoxContent ul li .listBox .itemName a, .frontBoxContent ul li .listBox .itemInfo a {
	color: #222;
}

.frontBoxContent ul li .listBox .itemName span, .frontBoxContent ul li .listBox .itemInfo span {
	display: block;
}
.frontBoxContent ul li .listBox .itemSum {
	float: right;
	font-weight: bold;
	vertical-align: middle;
}

.frontBoxContent ul li .listBox .itemSum button {
	font-size: 0.8rem;
}

.frontBoxFoot {
	padding: 0.5rem;
	background-color: #9bccdb;
	position: absolute;
	bottom: 0;
	width: 100%;
	min-height: 61px;
}

.frontBoxFoot .button {
	width: 100%;
	text-align: center;
	margin-bottom: 0;
	text-transform: uppercase;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #004d64;
	z-index: 99;
	color: #FFF;
}

.footerContainer {
	position: relative;
	padding: 1rem;
}

.footerToggle {
	position: absolute;
	top: -20px;
	width: 100%;
	text-align: center;
}

.footerToggle button {
	background-color: #004d64;
	border-radius: 40px;
	border: none;
	padding: 0;
	width: 40px;
	height: 40px;
	vertical-align: top;
}

.footerToggle button:hover {
	background-color: #004d64;
}

.footerToggle button svg {
	width: 20px !important;
}

.checkBoxButton {
	padding: 0.375rem 0.75rem 0.375rem 2rem;
	display: inline-block;
	background: #0680a4;
    background: -webkit-linear-gradient(#05617d,#0680a4);
    background: -o-linear-gradient(#05617d,#0680a4);
    background: -moz-linear-gradient(#05617d,#0680a4);
	background: linear-gradient(#05617d,#0680a4);
	text-transform: uppercase;
}

.checkBoxButton label {
	margin-bottom: 0;
}

.orderFooterButtons .btn-group .confirmBtn {
	margin-left: 3rem;
}

.orderFooterButtons .btn-group .offerBtn {
	margin-left: 1rem;
}

.orderFooterButtons .btn-group .btn-outline-primary, .orderFooterButtons .btn-group .btn-outline-warning {
	color: #FFF;
	background-color: transparent !important;
}

.orderFooterSum {
	font-size: 1.2rem;
	line-height: 36px;
}

.orderFooterSum.marginBottom {
	margin-bottom: 1rem;
}

 .wish-list-pane .ListHeader {
	 padding: 0.5rem;
	 border: rgba(255, 255, 255, 0.8);
 }

 .wish-list-pane .ListHeader h4 {
	margin-bottom: 0;
}

.search-results-pane, .wish-list-pane {
	max-height: calc(100vh - 205px);
	overflow-y: scroll;
	padding: 0.5rem !important;
}

.search-results-pane {
	background-color:#afd4bf;
}

.wish-list-pane {
	background-color: #9bccdb;
}

.search-results-pane ul, .wish-list-pane ul {
	padding-left: 0;
}

/*.wish-list-pane ul {
	background-color: rgba(255, 255, 255, 0.8);
}*/

.search-results-pane ul li, .wish-list-pane ul li {
	list-style-type: none;
	margin-bottom: 2px;
	background-color: #FFF;
	font-size: 0.8rem;
}

.scanner-results td{
	padding: .1rem;
	font-size: 16px;
}

.scanner-results textarea{
	padding: 1rem;
	min-height: 250px;
}

.btn i {
	margin-right: 0.3rem;
}

.search-results-pane ul li:nth-child(even), .wish-list-pane u li:nth-child(even) {
	background-color: #d7e9df;
}

.search-results-pane ul li:last-child, .wish-list-pane ul li:last-child {
	margin-bottom: 2rem;
}

.search-results-pane .selected, .search-results-pane ul li:nth-child(even).selected, .wish-list-pane .selected, .wish-list-pane ul li:nth-child(even).selected {
	background-color: #f2da92;
}

.search-results-pane .searchItem, .wish-list-pane .searchItem {
	padding: 10px 5px !important;
}

.search-results-pane .searchItem.info, .wish-list-pane .searchItem.info {
	padding: 10px 5px 10px 10px !important;
}

.search-results-pane .searchItem.expand button.btn, .wish-list-pane .searchItem.expand button.btn {
	height: 100%;
	padding: 0;
}

.search-results-pane .searchItem.info button.btn i, .wish-list-pane .searchItem.info button.btn i {
	height: 100%;
}

.search-results-pane .searchItem.add, .wish-list-pane .searchItem.add {
	padding: 10px 10px 10px 5px !important;
}


.search-results-pane .searchItem .stock,
.wish-list-pane .searchItem .stock,
.orderLineInfo .stock{
	color: #36935e;
	font-weight: bolder;
}

.orderLineInfo .rab-ii{
	color: #24aed5;
	/*font-weight: lighter;*/
}

.search-results-pane .searchItem.add button.btn, .wish-list-pane .searchItem.add button.btn {
	height: 100%;
	background-color: transparent !important;
	padding: 0;
}

.search-results-pane .searchItem.add button.btn i, .wish-list-pane .searchItem.add button.btn i {
	width: 35px;
	height: 35px;
	border-radius: 35px;
	line-height: 35px;
	background-color: #24aed5 !important;
}

.search-results-pane .itemDescription, .search-results-pane .itemAction, .wish-list-pane .itemDescription, .wish-list-pane .itemAction {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #ededed;
}
.search-results-pane .itemAction, .wish-list-pane .itemAction {
	background-color: #dbdbdb;
}
.search-results-pane .itemAction button.btn, .wish-list-pane .itemAction button.btn, .order-pane .itemAction button.btn {
	margin-bottom: 0;
}

.search-results-pane .itemAction .synonymFormRow, .wish-list-pane .itemAction .synonymFormRow, .order-pane .itemAction .synonymFormRow {
	margin-bottom: 0;
}

.synonymFormRow textarea.form-control {
	margin-bottom: 1rem;
}
.synonymFormRow  {
	background-color: rgba(255, 255, 255, 0.4);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.synonymFormRow .synonymList {
	padding: 5px 0 10px 0;
}

.synonymFormRow .badge {
	display: inline-block;
	padding: 10px 15px;
	margin-right: 10px;
	text-transform: uppercase;
	margin-bottom: 10px;
	position: relative;
}

.synonymFormRow .badge-primary {
	background-color: transparent;
	color: black;
	padding: 10px 0;
	margin-right: 5px;
}

.synonymFormRow .badge-primary:after {
	content: " |";
	color: darkgray;
}

.synonymFormRow .badge .deleteBadge {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	line-height: 15px;
	background-color: tomato;
	color: #FFF;
	position: absolute;
	display: block;
	right: -5px;
	top: -5px;
	cursor: pointer;
}

.synonymFormRow textarea.form-control {
	margin-bottom: 1rem;
}

.synonymFormRow ul.synonymsList {
	margin: 0;
	padding: 0;
}

.synonymFormRow ul.synonymsList li {
	list-style-type: none;
	display: inline-block;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}

.synonymFormRow ul.synonymsList li:nth-child(even) {
	background-color: transparent !important;
}

.synonymFormRow ul.synonymsList li:last-child {
	margin-bottom: 0 !important;
}

.synonymFormRow ul.synonymsList li span {
	display: block;
	padding: 10px 15px;
}

/*
.synonymFormRow .badge {
	margin-right: 10px;
}*/


.user-search-results-pane {
	background-color: #fff4de;
	max-height: calc(100vh - 70px);
	overflow: scroll;
	padding: 1rem;
}

.user-search-results-pane ul {
	padding: 0;
	margin: 0;
}

.user-search-results-pane ul li {
	list-style-type: none;
	cursor: pointer;
	padding: 0.5rem;
	border-bottom: 1px solid #c18d27;
}

.user-search-results-pane ul li:hover {
	background-color: #FFF;
}

.selected {
    background-color: #c3e6f0;
}

.new {
    background-color: rgba(162, 43, 255, 0.51);
}


code {
    font-family: Hasklig, 'Roboto Mono', monospace;
}

.main-pane {
	/*padding: 0 1rem;
	overflow-x: hidden;
	overflow-y: scroll;*/
	max-height: calc(100vh - 154px);
}

.dashboard

.order-line {
	border-bottom: 2px solid #FFF;
}

.order-line .itemDescription, .order-line .itemComments  {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: rgba(255, 255, 255, 0.4);
	margin-bottom: 1rem;
}

.order-line .itemDiscountPane {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: rgba(255, 255, 255, 0.6);
	margin-bottom: 2px;
	margin-top: 2px;
}

.sliderContainer .rangeslider {
	margin: 12px 0 !important;
}

.order-line .itemDescription .itemImg {
	overflow: hidden;
}
.itemDetails ul li {
	padding: 0.5rem;
}

.order-line .itemDescription .itemImg img {
	max-width: 100%;
}

.internalComment textarea {
	background-color: #fff6d7;
}

.orderLineAction {
	padding-bottom: 1rem;
}

.orderLineInfo {
	padding: 10px 10px !important;
	font-size: 0.80rem;
}

.order-line .row .orderLineInfo:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.3);
}

.orderLineBtn {
	position: relative;
}

.commentDot {
	position: absolute;
	right: 10px;
	top: 15px;
	width: 10px;
	height: 10px;
	border-radius: 15px;
	background-color: #222;
}

.commentDot.both {
	background-color: #1d7242;
}

.commentDot.order {
	background-color: #24aed5;
	right: 15px;
}

.commentDot.internal {
	background-color: #c18d27;
	right: 10px;
}

.orderLineBtn button.btn {
	width: 100%;
	height: 100%;
	padding: 0;
	vertical-align: middle;
	background-color: transparent !important;
	border: none;
}

.orderLineBtn button.btn i {
	width: 35px;
	height: 35px;
	border-radius: 35px;
	line-height: 33px;
}


.orderLineBtn button.btn-primary i, .orderLineBtn button.btn-primary:hover i {
	background-color: #067ea2 !important;
}


.orderLineBtn button.btn-success i, .orderLineBtn button.btn-success:hover i {
	background-color: #36935e !important;
}

.orderLineBtn button.btn-outline-success i, .orderLineBtn button.btn-outline-success:hover i {
	color: #36935e !important;
}




.itemInfo {
	line-height: 30px;
}

.itemInfo.itemSum {
	font-weight: bolder;
}

.itemDiscountPercent.form-control,  .itemDiscountPrice.form-control {
	background-color: transparent !important;
	border: none !important;
	display: inline-block;
	padding: 0;
	line-height: 35px;
	height: 35px;
	font-weight: bold;
}

.itemDiscountPercent.form-control {
	max-width: 35px;
	text-align: right;
}

.itemDiscountPrice.form-control {
	width: auto;
}

.itemAmount .imput-group {
	width: auto !important;
}

.itemAmount input.form-control {
	padding-right: 0 !important;
	font-size: small;
	height: calc(1.5em + .75rem + 4px);
}

.DayPickerInput {
	width: 115px;
}

.DayPickerInput input.form-control {
	border-radius: 0 !important;
	border-color: #fff;
	font-size: 13px;
	height: 38px;
}

.DayPickerInput + .input-group-append {
	border-right: 1px solid #fafafa;
}

.main-container {
	/*margin-top: 70px;*/
	background-color: aliceblue;
}

.main-container .btn {
	font-size: 0.83rem;
	/*padding: 0.5rem 0.6rem;*/
	/*margin: 0 0.3rem;*/
}

.toolBoxLeft, .toolBoxRight {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.companyInfo.loading {
	opacity: .1;
	color: gray;

}


.companyInfo {
	font-size: 0.9rem;
}

.companyMenu {
	font-size: 0.9rem;
}

.companyInfo .companyName {
	font-size: 0.9rem;
	/*padding: 0 0 0 1rem;*/
	line-height: 0.9rem;
	margin: 0;
}

.companyInfo .companyName i {
	margin: 0 5px;
	font-size: 1.2rem;
}

.companyInfo .companyId {
	font-size: 0.8rem;
	display: block;
	padding-left: 1rem;
}
.companyInfoExpand {
	margin-top: 1rem;
	font-size: 0.9rem;
}

.order-pane, .dash-pane {
	max-height: calc(86vh - 75px);
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: white;
	box-sizing: border-box;
	padding: 0.5rem 0.5rem 3rem 0.5rem;
}

.order-pane.minified .order-line .orderLineInfo {
	font-size: 0.8rem;
}

.main-pane .list-pane {
	padding: 0.5rem 0.5rem 3rem 0.5rem;
	overflow-y:scroll;
	max-height: calc(86vh - 95px);
}

.order-pane ul, .list-pane ul {
	padding-left: 0;
}

.order-pane ul li, .list-pane ul li {
	list-style-type: none;
	margin-bottom: 2px;
}

.order-pane ul li.selected, .order-pane ul li:nth-child(odd).selected, .order-pane ul li:nth-child(even).selected {
	background-color: #c3e6f0;
}

.list-pane ul li.selected, .list-pane ul li:nth-child(odd).selected, .list-pane ul li:nth-child(even).selected {
	background-color: #c3e6f0;
}

.order-pane ul li:nth-child(odd), .list-pane ul li:nth-child(odd) {
	background-color: #ededed;
	transition: background-color 500ms linear;
}

.order-pane ul li.new, .list-pane ul li.new  {
	background-color: greenyellow !important;
	transition: background-color 500ms linear;
}



.order-pane ul li:nth-child(even), .list-pane ul li:nth-child(even) {
	background-color: #dbdbdb;
}

.order-pane ul li:last-child, .list-pane ul li:last-child {
	margin-bottom: 2rem;
}

.list-pane .listHeader, .main-pane .listHeader {
	border-bottom: 2px solid #9bccdb;
	margin-top: 0.5rem;
	margin-bottom: 2px;
	padding: 10px;
}

.list-pane .listHeader {
	text-align: center;
}

.list-pane .listHeader h4, .main-pane .listHeader h4 {
	margin: 0;
	text-transform: uppercase;
}

.list-pane .listHeader h4, .main-pane .listHeader h4 a.btn {
	margin-right: 1rem;
}

.list-pane .ListItemInfo .btn {
	background-color: transparent;
}

.list-pane .ListItemInfo .btn i svg {
	height: 20px;
	width: 20px;
}

.list-pane .ListItemInfo.itemName h5 {
	line-height: 36px;
	margin-bottom: 0;
}

.list-pane .ListItemInfo.itemName h5 a {
	color: #222;
}

.listItemBox {
	padding: 10px;
}
.list-pane .itemDescription {
	padding-top: 1rem;
	background-color: #ededed;
}

.list-pane .itemAction {
	padding-top: 0.5rem;
	background-color: #e3e3e3;
}

header#top {
	/*position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;*/
	height: 70px;
	line-height: 70px;
	color: #FFF;
}

/*header#top.product-search {
	background: #0680a4;
	background: -webkit-linear-gradient(#05617d,#0680a4);
	background: -o-linear-gradient(#05617d,#0680a4);
	background: -moz-linear-gradient(#05617d,#0680a4);
	background: linear-gradient(#05617d,#0680a4);
}*/

header#top.product-search {
	background: #36935e;
    background: -webkit-linear-gradient(#1d7242,#36935e);
    background: -o-linear-gradient(#1d7242,#36935e);
    background: -moz-linear-gradient(#1d7242,#36935e);
    background: linear-gradient(#1d7242,#36935e);
}

header#top.user-search {
	color: #FFF;
	background: #0680a4;
	background: -webkit-linear-gradient(#05617d,#0680a4);
	background: -o-linear-gradient(#05617d,#0680a4);
	background: -moz-linear-gradient(#05617d,#0680a4);
	background: linear-gradient(#05617d,#0680a4);
}

/*header#top.user-search {
	color: #FFF;
	background: #3b9cb9;
    background: -webkit-linear-gradient(#3b9cb9,#81c4dc);
    background: -o-linear-gradient(#3b9cb9,#81c4dc);
    background: -moz-linear-gradient(#3b9cb9,#81c4dc);
    background: linear-gradient(#3b9cb9,#81c4dc);
}*/

/*header#top.user-search {
	color: #FFF;
	background: #e4b525;
    background: -webkit-linear-gradient(#c18d27,#e4b525);
    background: -o-linear-gradient(#c18d27,#e4b525);
    background: -moz-linear-gradient(#c18d27,#e4b525);
    background: linear-gradient(#c18d27,#e4b525);
}*/

header#top.order-search {
	color: #FFF;
	background: #e4b525;
    background: -webkit-linear-gradient(#c18d27,#e4b525);
    background: -o-linear-gradient(#c18d27,#e4b525);
    background: -moz-linear-gradient(#c18d27,#e4b525);
    background: linear-gradient(#c18d27,#e4b525);
}

.DayPickerInput {
	line-height: normal;
	color: black;
}

header#top #branding {
	position: relative;
}

header#top #branding .logo {
	position: absolute;
	top: 4px;
	left: 15px;
}

header#top #branding .logo img {
	width: 47px;
	height: 62px;
}

header#top #mainSearch .input-group {
	margin-top: 15px;
}

header#top .mainNav {
	text-align: right;
}

header#top .mainNav .btn-transparent {
	color: #FFF;
	padding: 0;
}

header#top .mainNav a {
	color: #FFF;
	display: block;
	height: 100%;
	line-height: 70px;
}

header#top #mainNav a img {
    width: 30px;
    height: auto;
}

header#top #mainNav a i.fas {
	font-size: 35px;
	vertical-align: middle;
	margin-top: -5px;
}

.content {
	margin-top: 70px;
	height: 100vh;
}

.content .container-fluid {
	height: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
}

footer.orderFooter {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	line-height: 60px;
	color: #FFF;
	background: #0680a4;
    background: -webkit-linear-gradient(#05617d,#0680a4);
    background: -o-linear-gradient(#05617d,#0680a4);
    background: -moz-linear-gradient(#05617d,#0680a4);
    background: linear-gradient(#05617d,#0680a4);
}

.orderFooterFields {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}

footer.orderFooter .quickOrder {
	text-align: center;
}

footer.orderFooter .orderAction {
	text-align: right;
}

.order-status i {
	margin-left: .2rem;
}

